// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/aaron/shipamax/git/shipamax-web/website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/aaron/shipamax/git/shipamax-web/website/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-chartering-teams-js": () => import("/Users/aaron/shipamax/git/shipamax-web/website/src/pages/chartering-teams.js" /* webpackChunkName: "component---src-pages-chartering-teams-js" */),
  "component---src-pages-contact-js": () => import("/Users/aaron/shipamax/git/shipamax-web/website/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-forgot-js": () => import("/Users/aaron/shipamax/git/shipamax-web/website/src/pages/forgot.js" /* webpackChunkName: "component---src-pages-forgot-js" */),
  "component---src-pages-freight-forwarders-js": () => import("/Users/aaron/shipamax/git/shipamax-web/website/src/pages/freight-forwarders.js" /* webpackChunkName: "component---src-pages-freight-forwarders-js" */),
  "component---src-pages-home-js": () => import("/Users/aaron/shipamax/git/shipamax-web/website/src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("/Users/aaron/shipamax/git/shipamax-web/website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/Users/aaron/shipamax/git/shipamax-web/website/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-ship-brokers-js": () => import("/Users/aaron/shipamax/git/shipamax-web/website/src/pages/ship-brokers.js" /* webpackChunkName: "component---src-pages-ship-brokers-js" */),
  "component---src-pages-thanks-js": () => import("/Users/aaron/shipamax/git/shipamax-web/website/src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

